window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});
document.addEventListener("DOMContentLoaded", function() {
  var input = document.getElementById('Address'); // Your specified input ID
  var autocomplete = new google.maps.places.Autocomplete(input);
});

